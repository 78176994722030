<template>
  <ams-table
    :ams_api_path="ams_api_path"
    :ams_type="ams_type"
    :ams_fields="ams_fields"
  />
</template>

<script>
import AmsTable from './AmsTable.vue'

export default {
  components: {
    AmsTable
  },
  data() {
    return {
      ams_type: 'collections',
      ams_api_path: 'api/admin/v1',
      ams_fields: [
       { 
          key: 'attributes.name',
          label: 'Name',
          sortable: true,
          filterable: true,
          class: 'text-left',
          sortDirection: 'asc' 
        },
       { 
          key: 'attributes.access-permission',
          label: 'Access Permission',
          sortable: true,
          filterable: true,
          class: 'text-center',
          sortDirection: 'asc' 
        },
      ]
    }
  }
}
</script>